@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");

/* Reset css --- */
* {
  padding: 0px;
  margin: 0px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  background: black;
  -webkit-overflow-scrolling: touch !important;
  overflow: auto !important;
  height: 100% !important;
}

*:focus {
  outline: 0;
}

#userLocation {
  background: red;
  font-size: 12px;
  height: auto;
  /* Adjust this value to center the text vertically */
  box-sizing: border-box;
  /* Ensure paddings don't increase the size of the textarea */

}

body {
  margin: 0;
  padding: 0;
  font-size: 14px !important;
  /* line-height: 16px; */
  font-family: "Montserrat", sans-serif !important;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

:focus {
  outline: 0;
}

a,
a:link {
  text-decoration: none;
  outline: none;
}

p {
  padding: 2px 0;
  margin: 0;
}

.clear {
  clear: both;
}

a {
  color: #36a1d6;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #0c528f;
}

a:focus {
  outline: none !important;
}

/* :hover {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
} */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

img {
  border: 0;
  max-width: 100%;
  height: auto;
}

::-webkit-input-placeholder {
  opacity: 1;
  color: var(--color-grey);
}

:-moz-placeholder {
  opacity: 1;
  color: var(--color-grey);
}

::-moz-placeholder {
  opacity: 1;
  color: var(--color-grey);
}

:-ms-input-placeholder {
  opacity: 1;
  color: var(--color-grey);
}

/* --- Reset css */

.scrollable,
.scrollable-notification {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollable::-webkit-scrollbar,
.scrollable-notification::-webkit-scrollbar {
  display: none;
}

:root {
  --color-white: white;
  --color-grey: #999999;
  --colo-blue: #223f5d;
  --color-light-blue: #3388ff;
  --color-red: #f68d99;
  --color-green: #6ee7b7;
  --color-yellow: #FFFF00;
}

.fullScreen {
  min-height: 100vh;
  /* padding: 0 20px; */
  /* overflow: hidden; */
}

.link-white {
  color: var(--color-white);
}

.link-white:hover {
  color: var(--color-white);
}

.link-white.link-bdr {
  border-bottom: 1px solid var(--color-white);
}

.link-grey {
  color: var(--color-grey);
}

.link-grey:hover {
  color: var(--color-grey);
}

.link-grey.link-bdr {
  border-bottom: 1px solid var(--color-grey);
}

.link-red {
  color: var(--color-red);
}

.link-red:hover {
  color: var(--color-red);
}

.link-red.link-bdr {
  border-bottom: 1px solid var(--color-red);
}

.width-200 {
  min-width: 200px !important;
}

.success {
  color: var(--color-green) !important;
}

.error {
  color: var(--color-red) !important;
}

.warning {
  color: var(--color-yellow) !important;
}

.bdr-none {
  border: none !important;
}

.no-bg {
  background: none !important;
}

.bgDark {
  background: #000812 !important;
}

.covered_div {
  font-size: 14px;
  line-height: 16px;
  font-weight: 800;
  color: #fff;
  padding: 10px;

}

.logo_div {
  border: 2px solid #ffffff;
  border-radius: 20px;
  width: 50%;
  /* height: 100%; */
  padding: 20px 0;
  margin: 0 auto;
  background-color: #141D28;
}

.logo {
  text-align: center;
  width: 55%;
  margin: auto;
}

.logo img {
  width: 100%;
}

.logo_loading {
  max-width: 300px;
  width: 50%;
  margin: auto;
}

.logo_loading img {

  animation: rotation 5s infinite linear;
}

.commentSrcrollDiv {
  height: 40vh;
  /* height: calc(50vh - 84); */
  overflow: auto;
  width: 95%;
  margin-top: 244px;
  scrollbar-width: 'thin'
}

.commentSrcrollDiv2 {
  height: 40vh;
  /* height: calc(50vh - 84); */
  overflow: auto;
  width: 95%;
  margin-top: 10px;
  scrollbar-width: 'thin'
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.welcome_back_div {
  margin: 30px 0 10px 0;
  text-align: center;
  font-size: 30px;
  line-height: 32px;
  font-weight: 600;
}


.modal-content {
  border: none;
}

.fullScreen-login-2 {
  /* background: url("../src/images/bg.jpg") no-repeat center center; */
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-white);
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
}

.fullScreen-login {
  /* background: url("../src/images/bg.jpg") no-repeat center center; */
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
}

.fullScreen-login::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  /* background: linear-gradient(270deg,
      rgba(153, 238, 255, 0) 0%,
      var(--token-5d2125f2-ea96-4015-b417-0b2003a177f4, rgb(6, 16, 29)) 100%); */
}

.loginWrap {
  width: 100%;
  position: relative;
  z-index: 1;
  /* margin-top: 15%; */
  /* padding: 16% 16px;*/
  /* height: 90vh; */
  overflow-y: auto;

}

.testing {
  height: auto;
  max-height: 40vh;
  overflow-y: auto;
}

.loginWrap.scrollable {
  height: calc(100vh - 100px);
  overflow-y: auto;
  overflow-x: hidden;
}

.loginWrap.scrollable.center {
  height: auto;
  max-height: calc(100vh - 160px);
  padding-bottom: 200px !important;
}

.loginInp {
  margin-top: 20px;
}

.loginInp button {
  border: none;
  background: #133e7a;
  height: 40px;
  text-align: center;
  color: grey;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  min-width: 100%;
  font-size: 15px;
  line-height: 17px;
  font-weight: 600;
}

.custom-check {
  color: var(--color-grey);
  cursor: pointer;
  display: block;
  font-size: 11px;
  line-height: 13px;
  padding-left: 25px;
  padding-top: 2px;
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.inputTxt {
  width: 100%;
  padding: 10px;
  padding-top: 21px;
  border-radius: 8px;
  /* background: var(--colo-blue); */
  background: #141D28;
  border: none;
  font-size: 15px;
  line-height: 17px;
  font-weight: 700;
  color: var(--color-white);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #fff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #141D28;
}

.label {
  color: var(--color-grey);
  font-size: 11px;
  line-height: 13px;
  font-weight: 400;
  position: absolute;
  left: 10px;
  top: 6px;
}

.loginInp-main {
  position: relative;
}

.inpIcon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
  line-height: 22px;
}

.button {
  border: none;
  background: var(--color-light-blue);
  height: 50px;
  text-align: center;
  color: var(--color-white);
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  min-width: 120px;
  font-size: 15px;
  line-height: 17px;
}

.button.button-disable {
  opacity: 0.3;
}

.button-dark {
  background: var(--colo-blue);
}

.button-success {
  background: var(--color-green);
  color: #0a0a0a;
  font-weight: 600;
}

.button-secondary {
  background: gray;
  color: #0a0a0a;
  font-weight: 600;
}

.button-error {
  background: var(--color-red);
  color: #0a0a0a;
  font-weight: 600;
}

.button-sm {
  height: 40px;
}

.pageBottom {
  position: fixed;
  bottom: 0;
  padding: 20px;
  z-index: 1;
  border-top: 2px solid #141D28;
}

.pageBottom a,
.pageBottom b {
  border-bottom: 1px solid var(--color-white);
  color: #fff;
  font-weight: 700;
}

.pageBottom p span {
  font-weight: 600;
  color: lightgray;
}

.loginWrap.scrollable .pageTop {
  background: #06101d;
}

.loginWrap.scrollable .pageBottom {
  background: #06101d;
}

.pageTop {
  position: absolute;
  width: 100%;
  left: 0 !important;
  top: 0 !important;
  padding: 0 20px;
  z-index: 99;
  min-height: 80px;
  display: flex;
  align-items: center;
}

.pageTop-left {
  width: 65%;
}

.profile_header h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #FFFFFF;
}

.pageTop-right {
  width: 35%;
  display: flex;
  justify-content: flex-end;
}

.pageTop-progress {
  width: 100%;
  position: relative;
  height: 5px;
  margin: 4px 0 0 0;
}

.pageTop-progress span {
  position: absolute;
  left: 0;
  top: 0;
  width: 20%;
  height: 100%;
  background: var(--color-light-blue);
}

.pageTop-right-title {
  font-size: 15px;
  line-height: 17px;
  font-weight: 500;
}

.backLink {
  display: flex;
  align-items: center;
}

.backLink span {
  font-size: 21px;
  line-height: 23px;
  font-weight: 700;
}

.backLink span.chatName {
  font-size: 15px;
  line-height: 17px;
}

.backLink-icon {
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background: var(--colo-blue);
  font-size: 29px;
  line-height: 31px;
  margin-right: 15px;
}

.backLink-icon.alt {
  margin-right: 0;
}

.backLink-icon.noBg {
  width: auto;
  height: auto;
}

.backLink a {
  display: flex;
  align-items: center;
  color: var(--color-white);
}

.infoTxt h1 {
  font-size: 20px;
  line-height: 22px;
  font-weight: 700;
}

.infoTxt h2 {
  font-size: 15px;
  line-height: 17px;
  font-weight: 500;
}

.infoTxt p {
  font-size: 11px;
  line-height: 13px;
}

.inpMsg {
  font-size: 13px;
  line-height: 16px;
  padding-top: 5px;
}

.inpMsg.error {
  color: var(--color-red);
}

.inpMsg.success {
  color: var(--color-green);
}

.envLarge {
  font-size: 50px;
}

.infotxtGroup {
  font-size: 13px;
  padding-top: 5px;
}

.infotxtGroup p {
  padding: 0;
  color: var(--color-grey);
}

.infotxtGroup p.error {
  color: var(--color-red);
}

.infotxtGroup p.success {
  color: var(--color-green);
}

.inviteLinkTitle {
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: 25px;
  padding: 0;
}

.inviteLink {
  padding-top: 16px;
  line-break: anywhere;
  color: #80a1f4;
  text-decoration: underline;
}

.otpInp {
  width: 100%;
  border: none;
  background: var(--color-light-blue);
  color: var(--color-white);
  font-size: 25px;
  border-radius: 9px;
  text-align: center;
  padding: 8px 0;
  font-weight: 700;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.login-solid {
  background: #06101d;
}

.login-solid::after {
  display: none;
}

.subsPlan-single {
  position: relative;
  margin-top: 20px;
}

.subsPlan-single.selected .subsPlan-img {
  opacity: 0.3;
}

.subsPlan-img {
  height: 260px;
  border-radius: 10px;
  overflow: hidden;
}

.subsPlan-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.subsPlan-txt {
  position: absolute;
  left: 0;
  bottom: 5px;
  padding: 5px 5px;
  padding-left: 10px;
  width: 100%;
}

.subsPlan-txt h3 {
  font-size: 11px;
  font-weight: 700;
  color: var(--color-grey);
}

.subsPlan-txt h4 {
  font-size: 14px;
  font-weight: 700;
  color: var(--color-white);
}

.subsDetails {
  padding-top: 20px;
}

.sponsorList li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.sponsorList li.selected .sponsor-dot {
  background: var(--color-green);
}

.sponsorList-left {
  display: flex;
  align-items: center;
}

.sponsorList-img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
}

.sponsorList-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sponsorName {
  padding-left: 10px;
  font-size: 15px;
  font-weight: 600;
}

.sponsor-dot {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background: var(--colo-blue);
  accent-color: var(--color-green);
  cursor: pointer;
}

.pageLink ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -1px;
  margin-left: -1px;
}

.pageLink ul li {
  width: 100%;
  min-height: 1px;
  padding-right: 1px;
  padding-left: 1px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  text-align: center;
}

.pageLink ul li a {
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  border: 3px solid var(--token-15472a62-679c-4814-b5e7-0a007fdedeb6, rgba(51, 136, 255, 0.2));
  color: rgba(51, 136, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 24px;
}

.pageLink .chip_footer li a {
  display: block;
  width: 50px;
  height: 50px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 24px;
}

.pageLink .chip_footer .icon {
  display: block;
  width: 32px;
  height: auto;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 24px;
}

.pageBottom.forLink {
  padding: 10px 0;
  background: var(--colo-blue);
}

.pageLink ul li {
  align-self: center;
}

.pageLink ul li.current-page a {
  background: var(--color-light-blue);
  border: 3px solid var(--color-light-blue);
  color: var(--color-white);
}

.pageLink ul li.current-page .icon {
  /* background: var(--color-light-blue); */
  /* border: 3px solid var(--color-light-blue); */
  color: var(--color-light-blue);
}

.pageLink ul li a.active {
  background: var(--color-light-blue);
  border: 3px solid var(--color-light-blue);
  color: var(--color-white);
}

.pageLink .chip_footer li.current-page a {
  background: transparent;
  border: none;
  color: var(--color-light-blue);
}

.pageLink .chip_footer li a.active {
  background: transparent;
  border: none;
  color: var(--color-light-blue);
}

.pageTop.forNotification {
  background: var(--colo-blue);
}

.pageTop h2 {
  font-size: 25px;
  font-weight: 700;
}

.pageTop-right.alt {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 45%;
}

.pageTop-right ul li {
  display: inline-block;
  margin-left: 4px;
  vertical-align: middle;
  position: relative;
}

.pageTop-right ul li:first-child {
  margin-left: 0;
}

.pageTop-right ul li a {
  width: 40px;
  height: 40px;
  color: var(--color-white);
  border-radius: 100px;
  background: rgba(51, 136, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  position: relative;
}

.notific-pop {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -3px;
  top: -6px;
  width: 18px;
  height: 18px;
  background-color: #99eeff;
  border-radius: 100%;
  font-size: 10px;
  color: #000000 !important;
  font-weight: 600;
}

.WagerTxt h1 {
  font-size: 35px;
  font-weight: 700;
}

.WagerTxt h2 {
  font-size: 11px;
  font-weight: 500;
  padding-top: 20px;
}

.WagerTxt h3 {
  font-size: 25px;
}

.filterDrop .dropdown-menu {
  color: var(--color-white);
  padding: 7px;
  width: 191px;
  box-shadow: 3px 1px 2px 0px rgba(0, 0, 0, 0.25);
  background: #06101d;
  border-radius: 11px;
}

.filterDrop h5 {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

.singleMenu {
  font-size: 12px;
  background: #fff;
  border-radius: 2px;
  height: 26px;
  line-height: 26px;
  padding: 0 8px;
  margin-top: 5px;
  color: #06101D;
  text-overflow: ellipsis;
  font-weight: 500;
  vertical-align: middle;
}

.singleMenu.active {
  background: var(--color-light-blue);
  font-weight: 700;
  color: var(--color-white);
}

.dropdown-group {
  margin-top: 15px;
}

.dropdown-group:first-child {
  margin-top: 0;
}

.dropdown-group .row {
  margin-left: -4px;
  margin-right: -4px;
}

.dropdown-group .col-6 {
  padding-left: 4px;
  padding-right: 4px;
}

.btn-dropBtn {
  color: var(--color-white) !important;
  padding: 0 27px 0 0 !important;
  position: relative;
}

.btn-dropBtn span {
  display: block;
  text-align: left;
  font-size: 22px;
  font-weight: 700;
  white-space: pre-wrap;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  line-height: 1.2;
}

.btn-dropBtn span+span {
  font-size: 22px;
  font-weight: 700;
  margin: -8px 0 0 0;
  line-height: 26px;
}

.btn-dropBtn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn-dropBtn::after {
  display: none;
}

.btn-dropBtn .toggleIcon {
  position: absolute;
  right: 0;
  top: 0;
  color: var(--color-white);
  font-size: 22px;
  line-height: 20px;
  transition: all 0.5s ease;
}

.btn-dropBtn[aria-expanded="true"] .toggleIcon {
  transform: rotate(180deg);
}

.singleMenu {
  position: relative;
}

.singleMenu:hover {
  background: var(--color-light-blue);
}

.singleMenu ul {
  position: absolute;
  left: 145px;
  top: 0;
  width: 160px;
  box-shadow: 3px 1px 2px 0px rgba(0, 0, 0, 0.25);
  background: #06101d;
  border-radius: 11px;
  padding: 7px;
  padding-top: 3px;
  opacity: 0;
  visibility: hidden;
}

.singleMenu:hover ul {
  opacity: 1;
  visibility: visible;
}

.closedList {
  position: absolute;
  left: 145px;
  top: 0;
  width: 160px;
  box-shadow: 3px 1px 2px 0px rgba(0, 0, 0, 0.25);
  background: #06101d;
  border-radius: 11px;
  padding: 7px;
  padding-top: 3px;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
}

.openedList {
  position: absolute;
  left: 186px;
  top: 35px;
  width: 160px;
  box-shadow: 3px 1px 2px 0px rgba(0, 0, 0, 0.25);
  background: #06101d;
  border-radius: 11px;
  padding: 7px;
  padding-top: 3px;
  opacity: 0;
  visibility: visible;
  opacity: 1;
  cursor: pointer;
}

.activeBtn {
  background: var(--color-light-blue);
  font-weight: 700;
  color: var(--color-white);
  margin-top: 6px;
  border-radius: 5px;
  padding: 0 8px;
  font-size: 13px;
  height: 26px;
  line-height: 26px;
}

.boardSingle {
  background: var(--colo-blue);
  border-radius: 18px;
  padding: 10px;
  margin-top: 20px;
  position: relative;
  color: var(--color-white);
}

.fullLink {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.boardSingle h2 {
  font-size: 12px;
  font-weight: 500;
}

.boardSingle h3 {
  font-size: 20px;
  font-weight: 700;
  padding-top: 4px;
}

.pageTop.forDrop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--colo-blue);
  z-index: 9 !important;
  /* position: fixed; */
}

.pageTop.forDrop1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #06101d;
  z-index: 9;
}

.zindex-99 {
  z-index: 99;
}

.userRatings ul {
  display: flex;
  justify-content: flex-end;
}

.userRatings ul li {
  display: inline-block;
  vertical-align: top;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(51, 136, 255, 0.2);
  color: rgba(51, 136, 255, 0.2);
  font-size: 10px;
  margin-left: 5px;
}

.userRatings ul li:first-child {
  margin-left: 0;
}

.userRatings ul li.active {
  background: var(--color-light-blue);
  border: 1px solid var(--color-light-blue);
  color: var(--color-white);
}

.boardSingle-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0 0 0;
}

.boardSingle-bottom-left .pageTop-right {
  width: auto;
}

.boardSingle-bottom-right p {
  line-height: 18px;
  text-align: right;
  /* color: var(--color-white) !important; */
}

.boardSingle-bottom .pageTop-right ul li a {
  background: var(--color-light-blue);
}

.boardSingle.accepted .boardSingle-bottom .pageTop-right ul li a {
  background: var(--color-white);
  color: var(--colo-blue);
}

.boardSingle.closed {
  background: var(--color-grey);
}

.boardSingle.closed {
  background: var(--color-grey);
}

.boardSingle.closed .userRatings ul li {
  border: 1px solid #5a5c5e;
  color: #5a5c5e;
}

.boardSingle.closed .userRatings ul li.active {
  background: #5a5c5e;
  border: 1px solid #5a5c5e;
  color: var(--color-grey);
}

.boardSingle.closed .boardSingle-bottom .pageTop-right ul li a {
  background: var(--color-white);
  color: var(--colo-blue);
}

.boardSingle.grey {
  background: var(--color-grey);
}

.boardSingle.grey {
  background: var(--color-grey);
}

.boardSingle.grey .userRatings ul li {
  border: 1px solid #5a5c5e;
  color: #5a5c5e;
}

.boardSingle.grey .userRatings ul li.active {
  background: #5a5c5e;
  border: 1px solid #5a5c5e;
  color: var(--color-grey);
}

.boardSingle.grey .boardSingle-bottom .pageTop-right ul li a {
  background: var(--color-white);
  color: var(--colo-blue);
}

.boardSingle.acceptedWrag {
  background: var(--color-green);
}

.boardSingle.acceptedWrag h2 {
  color: #0a0a0a !important;
}

.boardSingle.acceptedWrag h3 {
  color: #0a0a0a !important;
}

.boardSingle.acceptedWrag .boardSingle-bottom-right p {
  color: #0a0a0a !important;
}

.boardSingle.acceptedWrag .userRatings ul li {
  border: 1px solid var(--colo-blue);
  color: var(--colo-blue);
}

.boardSingle.acceptedWrag .userRatings ul li.active {
  background: var(--colo-blue);
  color: var(--color-white);
}

.boardSingle.acceptedWrag .boardSingle-bottom .pageTop-right ul li a {
  background: var(--colo-blue);
  color: var(--color-white);
}

.boardSingle.acceptedWrag .notific-pop {
  background: var(--color-white);
}

.boardSingle.my {
  background: var(--color-light-blue);
}

.boardSingle.my .userRatings ul li {
  border: 1px solid var(--colo-blue);
  color: var(--colo-blue);
}

.boardSingle.my .userRatings ul li.active {
  background: var(--colo-blue);
  color: var(--color-white);
}

.boardSingle.my .boardSingle-bottom .pageTop-right ul li a {
  background: var(--color-white);
  color: var(--color-light-blue);
}

.notific-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-grey);
  padding: 12px 10px;
}

.notific-single-left {
  display: flex;
  align-items: center;
  width: calc(100% - 65px);
}

.notific-single-img {
  position: relative;
  max-width: 40px;
  width: -webkit-fill-available;
  width: 100%;
  height: 40px;
  background: var(--color-light-blue);
  border-radius: 100%;
}

.notific-single-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.notific-single-right {
  width: 90px;
  font-size: 10px;
  font-weight: 600;
  text-align: right;
}

.notific-single-right img {
  height: 14px;
}

.notific-single-right svg {
  font-size: 25px;
  color: var(--color-light-blue);
}

.notific-single-right svg.disable {
  opacity: 0.2;
}

.notific-single-right .msgDisable {
  color: var(--color-grey);
}

.notific-single-txt {
  width: 100%;
  padding: 0 0 0 15px;
  font-size: 12px !important;
}

.notific-single-txt p {
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: -webkit-box; */
  /* -webkit-line-clamp: 3; */
  -webkit-box-orient: vertical;
  line-height: 16px;
  color: var(--color-white);
  font-weight: 500;
}

.notific-single-txt p.fullTxt {
  text-overflow: inherit;
  display: block;
}

.notific-single-txt p.fullTxt.link a {
  color: #8B8E92;
  font-weight: 500;
  margin-left: 10px;
  font-size: 11px;
  display: inline;
}

.notific-single-txt p.fullTxt.link a:first-child {
  margin-left: 0;
}

.notific-single-txt p span.time {
  color: var(--color-grey);
  padding-left: 10px;
  font-weight: 500;
}

.notific-single-txt p span.msgDisable {
  color: var(--color-grey);
  font-weight: 500;
}

.notificWrap {
  padding-left: 0;
  padding-right: 0;
  justify-content: flex-start;
}

.notific-single.nested {
  padding-left: 40px;
}

.notific-single.nested .notific-single-img {
  width: 30px;
  height: 30px;
}

.notific-single.nested .notific-single-txt {
  width: calc(100% - 30px);
}

.notific-single:last-child {
  border: none;
}

.notific-single-txt button {
  border: none;
  color: var(--color-white);
  background: var(--color-green);
  border-radius: 3px;
  padding: 5px;
  padding: 2px 10px;
  font-size: 12px;
  margin-right: 5px;
}

.notific-single-txt button.error {
  background: var(--color-red);
}

.notific-single.forMessage .notific-single-left {
  width: 100%;
}

.notific-single.forMessage {
  width: 100%;
  flex: 0 0 100%;
  position: relative;
}

.chip .notificationContainer {
  width: 40px;
  height: 40px;
  color: var(--color-white);
  border-radius: 100px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  position: relative;
}

.notificationContainer {
  width: 40px;
  height: 40px;
  color: var(--color-white);
  border-radius: 100px;
  background: rgba(51, 136, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  position: relative;
}

.addListBtn a {
  color: var(--color-white);
  font-size: 17px;
}

.addListBtn a.chaLink {
  font-size: 20px;
  font-weight: 700;
}

.notific-single-right .custom-check {
  width: 20px;
  height: 20px;
  padding: 0;
}

.searchFr {
  padding: 20px;
  padding-top: 10px;
}

.searchFr2 {
  padding: 20px;
  /* padding-top: 72px; */
}

.searchFr2 label {
  display: block;
  font-size: 20px;
}

.searchFr2 input[type="text"] {
  width: 100%;
  border: none;
  background: none;
  padding: 10px;
  color: var(--color-white);
}

.searchFr label {
  display: block;
  font-size: 20px;
}

.searchFr input[type="text"] {
  width: 100%;
  border: none;
  background: none;
  padding: 10px;
  color: var(--color-white);
}

.pageBottom.chatInp {
  background: #000812;
  display: flex;
  align-items: center;
  padding: 10px 10px 36px 10px;
}

.pageBottom.chatInp img {
  height: 36px;
  width: 36px;
  border-radius: 50%;
}

.addChat {
  width: 40px;
  height: 34px;
  background: var(--color-light-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 24px;
  color: var(--color-white);
}

.addChat>input {
  display: none;
}

.chattxtBox {
  /* width: calc(100% - 40px); */
}

.chattxtBox input {
  width: 100%;
  background: blue;
  border: none;
  padding: 5px 0;
  color: var(--color-white);
  -webkit-font-smoothing: auto;
  resize: none;
  vertical-align: middle;
  height: 31px;
}

.chattxtBox textarea {
  flex-grow: 1;
  width: 100%;
  background: none;
  border: none;
  padding: 5px 0;
  color: var(--color-white);
  -webkit-font-smoothing: auto;
  resize: none;
  vertical-align: middle;
  height: 31px;
}

/*
.chattxtBox button {
  color: #3388FF;
  font-weight: 600;
  background: no-repeat;
  border: navajowhite;
  text-align: right;
  margin-left: 14px;
  vertical-align: bottom;
  padding-bottom: 5px;
}
*/

.chatWindow-single {
  padding-left: 100px;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
  /* position: absolute; */
  /* right: 20px; */
  /* width: fit-content; */
  /* width: -moz-fit-content; */
}

.chatWindow-single-in {
  position: relative;
  background: var(--colo-blue);
  border-radius: 15px;
  padding: 10px;
}

.chatWindow-single-in p {
  padding: 0;
  line-height: 18px;
}

.messageDot {
  width: 10px;
  height: 10px;
  background: var(--colo-blue);
  position: absolute;
  right: -8px;
  bottom: -4px;
  border-radius: 50px;
}

.chatWindow-single.left,
.chatWindow-single-left {
  padding-left: 0;
  padding-right: 100px;
}

.chatWindow-single.left .chatWindow-single-in {
  background: #504e4e;
}

.chatWindow-single-left .chatWindow-single-in {
  background: #504e4e;
  display: flex;
  justify-content: flex-end;
  /* position: absolute; */
  /* right: 20px; */
  width: fit-content;
  width: -moz-fit-content;

}

.chatWindow-single-left {}

.chatWindow-single.left .messageDot {
  background: #504e4e;
  right: inherit;
  left: -8px;
}

.chatWindow-single-left .messageDot {
  background: #504e4e;
  right: inherit;
  left: -8px;
}

.chatWindow-single.waiting {
  padding-left: 50px;
  padding-right: 0;
}

.chatWindow-single.left.waiting {
  justify-content: flex-start !important;
}

.chatWindow-single.waiting .chatWindow-single-in {
  background: var(--color-light-blue);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatWindow-single.waiting .messageDot {
  background: var(--color-light-blue);
}

.chatWindow-single.accept {
  padding-right: 50px;
}

.chatWindow-single.accept .chatWindow-single-in {
  background: var(--colo-blue);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatWindow-single.accept .messageDot {
  background: var(--colo-blue);
}

.chatWindow-single.accept .chatStatIcon {
  color: var(--colo-blue);
}

.chatWindow-single.left.accepted {
  padding-right: 50px;
}

.chatWindow-single.accepted .chatWindow-single-in {
  background: var(--color-green);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatWindow-single.accepted .chatWindow-single-in p {
  color: #0a0a0a;
}

.chatWindow-single.accepted .messageDot {
  background: var(--color-green);
}

.chatWindow-single.accepted .chatStatIcon {
  background: #0a0a0a;
  color: var(--color-green);
}

.chatWindow-single.accepted .chatStatTxt {
  color: #0a0a0a;
  font-weight: 600;
}

.chatWindow-single.right.accepted {
  padding-right: 0;
  padding-left: 50px;
}

.chatWindow-single.left {
  padding-right: 50px;
  padding-left: 0px;
}

.chatWindowWrap {
  padding-left: 0;
  padding-right: 0;
  justify-content: flex-end;
}

.scrollable-chat {
  overflow-x: hidden;
  overflow-y: auto;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.chatHeaderMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
}

.chatWindow .plusBtn {
  width: 30px;
  height: 30px;
  background: var(--color-light-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -15px;
  top: -15px;
  border-radius: 50px;
  font-size: 20px;
  color: var(--color-white);
}

.participantList {
  position: absolute;
  right: -13px;
  top: 70%;
  background-color: var(--color-light-blue);
  padding: 7px;
  border-radius: 6px;
  z-index: 999;
}

.chatStat {
  display: inline-block;
  width: 70px;
  position: relative;
  text-align: center;
}

.chatStatIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: var(--color-white);
  color: var(--color-light-blue);
  margin: 0 auto;
  border-radius: 100px;
  font-size: 22px;
}

.themeModal .modal-dialog-centered {
  /* align-items: flex-end !important;
  min-height: calc(100% - 0px) !important; */
}

.themeModal .modal-dialog {
  /* margin: 0;
  padding: 0; */
}

.themeModal .modal-content {
  border: none;
  box-shadow: none;
  border-radius: 0;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background: #06101d !important;
  color: var(--color-white);
}

.modal.fade .modal-dialog {
  transform: translate(0, 50px) !important;
}

.modal.show .modal-dialog {
  transform: none !important;
}

.welcomeWrag {
  padding: 100px 0;
  padding-top: 80px;
}

.welcomeWrag h2 {
  font-size: 18px;
}

.closeModal {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 15px;
  text-align: center;
  font-size: 35px;
}

.welcomeForm-single {
  margin-top: 20px;
}

.welcomeForm-single label {
  display: block;
  font-size: 14px;
  padding-bottom: 5px;
}

.welcomeForm-single label span {
  font-weight: 700;
}

.simpleInp {
  width: 100%;
  border: none;
  background: var(--colo-blue);
  color: var(--color-white);
  border-radius: 10px;
  padding: 13px 16px;
  text-overflow: ellipsis;
}

.acceptWrag {
  padding-top: 10px;
}

.acceptWrag h2 {
  font-size: 18px;
  font-weight: 700;
}

.confList ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #222;
  padding: 15px 0;
}

.confList ul {
  padding-top: 10px;
}

.confList ul li:first-child {
  border-top: 0;
}

.userImg {
  width: 30px;
  height: 30px;
  background: var(--color-light-blue);
  border-radius: 50px;
  margin-right: 7px;
}

.userImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chatLoading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatLoading-left-img {
  width: 116px;
  height: 116px;
  overflow: hidden;
  border-radius: 100%;
}

.chatLoading-left-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.chatLoading-right {
  padding-left: 15px;
}

.chatLoading-right h2 {
  font-size: 18px;
  line-height: 26px;
}

/* .wraggerDetails{ padding-bottom: 40px;} */
.wraggerDetailsWrap {
  padding-left: 0;
  padding-right: 0;
}

.wraggerDetailsBox {
  background: var(--colo-blue);
  padding: 20px;
}

.modalContent {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 27px;

}

.termAndC .modal-content {
  background: var(--colo-blue);
  border-radius: 12px;
  color: white;
  padding: 26px;
  height: 350px;
  position: relative;
}

.wraggerDetailsBox h1 {
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 10px;
}

.wraggerDetailsBox h2 {
  font-size: 22px;
  padding-top: 15px;
}

.wraggerDetailsBox .userRatings ul {
  justify-content: flex-start;
  padding-top: 20px;
}

.wraggerDetailsBox .userRatings ul li {
  width: 25px;
  height: 25px;
}

.wraggerDetailsBox .chatStat {
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.wraggerDetailsBox .chatStatIcon {
  margin: 0;
  width: 45px;
  height: 45px;
}

.wraggerDetailsBox .chatStatTxt {
  display: inline-block;
  line-height: 12px;
  font-size: 12px;
  padding-left: 15px;
  color: var(--color-white);
}

.wraggerDetails .acceptWrag {
  padding: 20px;
}

.wraggerDetails .welcomeForm {
  padding: 12px;
  padding-top: 0;
}

.wraggerDetails .welcomeWrag {
  padding-top: 0;
  padding-bottom: 0;
}

.pageBottom.blue {
  background: #06101d;
  padding-top: 10px;
  padding-bottom: 10px;
}

.chatInp-alt .chattxtBox {
  width: calc(100% - 36px);
  padding-left: 0;
}

.noWragger h6 {
  font-size: 35px;
  font-weight: 700;
}

.wHistory {
  /* display: flex; */
  align-items: center;
  /* justify-content: space-between; */
  /* border-bottom: 1px solid #8b8e92; */
  /* padding: 12px 0; */
}

.wHistory:last-child {
  border-bottom: 0;
}

.wHistory p {
  margin: 0;
  padding: 0;
  font-size: 13px;
  line-height: 18px;
}

.wHistory-right p {
  text-align: right;
}

.wraggerDetailsBox.blue {
  background: var(--color-light-blue);
  padding-top: 20px;
}

.pageTop.blue {
  background: var(--color-light-blue);
}

.pageTop.blue .backLink-icon.noBg {
  background: none;
}

.wraggerDetailsBox.blue .userRatings ul li {
  border: 1px solid var(--colo-blue);
  color: var(--colo-blue);
}

.wraggerDetailsBox.blue .userRatings ul li.active {
  background: var(--colo-blue);
  color: var(--color-white);
}

.pageTop.green {
  background: var(--color-green);
}

.wraggerDetailsBox.green {
  background: var(--color-green);
  color: var(--colo-blue);
  padding-top: 20px;
}

.pageTop.green .backLink-icon.noBg {
  background: none;
}

.wraggerDetailsBox.green .userRatings ul li {
  border: 1px solid var(--colo-blue);
  color: var(--colo-blue);
}

.wraggerDetailsBox.green .userRatings ul li.active {
  background: var(--colo-blue);
  color: var(--color-white);
}

.wraggerDetailsBox.green .chatStatIcon {
  background: var(--colo-blue);
  color: var(--color-green);
}

.wraggerDetailsBox.green .chatStatTxt {
  color: var(--colo-blue);
}

.wraggerDetailsBox.grey {
  background: #8b8e92;
  padding-top: 20px;
}

.wraggerDetailsBox.grey .userRatings ul li {
  border: 1px solid #5a5c5e;
  color: #5a5c5e;
}

.wraggerDetailsBox.grey .userRatings ul li.active {
  background: #5a5c5e;
  color: #8b8e92;
}

.wraggerDetailsBox.grey .chatStatIcon {
  background: #5a5c5e;
  color: #fff;
}

.comImg {
  margin-top: 10px;
  max-width: 209px;
}

.button-white {
  background: var(--color-white);
  color: var(--colo-blue);
  font-weight: 500;
  width: 45%;
}

.button-red {
  background: var(--color-red);
  color: #000;
  font-weight: 500;
}

.termsModal .modal-content {
  background: #223f5d;
  color: var(--color-white);
}

.termsModal p {
  font-size: 12px;
  line-height: 24px;
}

.modalBack {
  font-size: 30px;
  line-height: 30px;
}

.termsTxt {
  padding-top: 20px;
  padding-bottom: 30px;
}

.chipHeading {
  margin: 10px 0;
  padding: 7px 0 7px 12px;
  border-left: 5px solid #3388FF;
}

.chipHeading h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
}

.chipList::-webkit-scrollbar {
  width: 0;
  display: none;
}

.chipList a {
  color: #141D28;
}

.chipList-single:focus {
  outline: none;
}

.chipList-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #141D28;
  padding: 15px 12px;
  outline: none;
}

.txtBlue {
  color: #99eeff;
}

.txtGrey {
  color: #8B8E92;
}

.txtGrey .chipList-single:last-child {
  border-bottom: none;
}

.chipLink-Ad {
  margin-top: 20px;
  position: relative;
  height: 165px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 12px; */
}

.chipLink-Ad img {
  height: inherit;
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.chipLink-single {
  margin-top: 20px;
  position: relative;
  height: 165px;
  background: #223f5d;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
}

.chipLink-single h2,
.chipLink-Ad h2 {
  font-size: 20px;
  font-weight: 700;
}

.chipLink-arrow {
  position: absolute;
  right: 12px;
  bottom: 12px;
  font-size: 30px;
  line-height: 30px;
}

.btn-round {
  border-radius: 50px;
}

.bankName p {
  text-align: right;
  font-size: 11px;
  color: #e6e6e6;
}

.bankInp {
  position: relative;
}

.bankInp span {
  position: absolute;
  right: 0;
  bottom: 12px;
  font-size: 12px;
  font-weight: 600;
}

.bankInp input {
  width: 100%;
  background: none;
  border: none;
  border-bottom: 2px solid var(--color-green);
  color: var(--color-green);
  font-size: 18px;
  padding-bottom: 6px;
}

.bankInp input.white {
  border-bottom: 2px solid var(--color-white);
  color: var(--color-white);
}

.smalltxt {
  font-size: 11px;
  line-height: 12px;
}

.deposit-initiated {
  padding-top: 50px;
}

.deposit-stat p {
  font-size: 13px;
  line-height: 16px;
}

.deposit-stat p .txtSm {
  color: #a0a0a0;
  font-size: 11px;
}

.deposit-stat ul {
  margin-top: 30px;
  margin-left: 10px;
}

.deposit-stat ul li {
  position: relative;
  margin-bottom: 40px;
  padding: 0 0 0 20px;
}

.deposit-stat ul li::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -3px;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #a0a0a0;
}

.deposit-stat ul li.active::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 0;
  margin-top: 0;
  width: 4px;
  height: 87px;
  background: var(--color-green);
}

.activeSign {
  position: absolute;
  left: -8px;
  top: -2px;
  width: 20px;
  height: 20px;
  background: var(--color-green);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-size: 30px;
  z-index: 1;
}

.noBg {
  background: none !important;
}

.backLink span small {
  display: inline-block;
  font-size: 13px !important;
  font-weight: 400 !important;
}

.qtIcon {
  color: var(--color-white);
  background: var(--color-red);
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  text-align: center;
  font-size: 10px;
  transform: translateY(-2px);
}

.userName {
  font-size: 22px;
  text-align: center;
  font-weight: 700;
  /* padding-bottom: 4px; */
}

.user-Img {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 100%;
  overflow: hidden;
}

.user-Img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .UserProfile .pageTop-right.alt {
  width: 150px;
} */
.UserProfile-cont {
  padding-top: 14px;
}

.UserProfile-cont1 {
  /* height: 78vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.UserProfile-cont1 .sec {
  background-color: #000812;
}

.prof-stat {
  text-align: center;
  color: #98eefe;
  font-weight: 700;
  padding-top: 10px;
}

.prof-link {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 15px;
  border: 1px solid #8b8e92;
  font-size: 18px;
  color: var(--color-white);
  padding: 6px 0;
}

.prof-link span {
  font-size: 22px;
  font-weight: 700;
  color: #98eefe;
  display: block;
  margin: -6px 0 0 0;
}

.prof-link2 {
  align-items: center;
  padding: 6px;
  gap: 8px;

  width: 80px;
  height: 24px;

  background: #3388FF;
  border-radius: 4px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;

  color: #FFFFFF;
}

.prof-link2 a {
  color: #fff;
}

.userStat-single {
  text-align: center;
}

.userStat-single {
  margin-top: 25px;
}

.userStat-single h2 {
  font-size: 16px;
  padding-bottom: 10px;
}

.userStat-single h3 {
  color: #98eefe;
  font-size: 13px;
  padding: 10px 0;
}

.userStat .col-4:nth-child(2) .userStat-single h3 {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.inviteFr h2 {
  text-align: center;
  font-weight: 700;
}

.inviteFr p {
  text-align: center;
}

.invInp {
  position: relative;
  margin-top: 10px;
}

.invInp input {
  width: 100%;
  border: none;
  color: #fff;
  background: var(--colo-blue);
  padding: 9px 13px;
  padding-right: 30px;
  border-radius: 5px;
}

.invInp svg {
  position: absolute;
  right: 6px;
  top: 9px;
  font-size: 18px;
}

.AccountSettings-block {
  margin-top: 30px;
}

.AccountSettings-block h2 {
  font-size: 20px;
}

.acsLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.acsLink p {
  color: var(--color-white);
  line-height: 16px;
  font-weight: 500;
  font-size: 18px;
}

.acsLink p span {
  display: block;
  font-size: 11px;
  font-weight: 400;
}

.acsLink-left {
  display: flex;
  align-items: center;
}

.acsLink-img {
  width: 45px;
  height: 45px;
  background: var(--color-light-blue);
  border-radius: 50px;
  overflow: hidden;
  margin-right: 10px;
}

.acsLink-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.acsLink-right svg {
  color: #fff;
  font-size: 24px;
}

.acsLink-icon {
  width: 45px;
  height: 45px;
  background: var(--color-white);
  border-radius: 50px;
  overflow: hidden;
  margin-right: 10px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.upload-photo {
  position: relative;
  text-align: center;
  font-weight: 700;
  margin-top: 10px;
  cursor: pointer;
}

.upload-photo input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

#myInput {
  width: 100%;
  outline: none;
  color: white;
  border: none;
  font-weight: 700;
  background: transparent;
}

.upload-photo span {
  /*   border-bottom: 1px solid #fff; */
  cursor: pointer;
}

.userName span {
  font-size: 15px;
  font-weight: 400;
}

.grey-text {
  color: #5c5c5c;
}

.grey-text2 {
  color: #adb5bd;
  font-size: 18px !important;
}

.acsLink p span.grey-text {
  padding-bottom: 6px;
  font-size: 14px;
}

.acsLink.red {
  color: var(--color-red) !important;
}

.acsLink.red p span.grey-text {
  color: var(--color-red) !important;
  font-size: 8px;
  line-height: 10px;
}

.acsLink.red p {
  color: var(--color-red) !important;
}

.acsLink.red .acsLink-right svg {
  color: var(--color-red) !important;
}

.smTxt {
  font-size: 12px;
}

.editInpWrap {
  margin-top: 15px;
}

.editInpWrap label {
  display: block;
  font-size: 11px;
}

.editInp {
  width: 100%;
  background: none;
  border: none;
  border-bottom: 1px solid var(--color-white);
  color: #fff;
  padding: 15px 15px;
}

.editInp option {
  background: #0a0a0a;
  border: none;
  border-radius: 3px;
  padding: 2px;
}

.editInp::-webkit-input-placeholder {
  opacity: 1;
  color: var(--color-white);
}

.editInp:-moz-placeholder {
  opacity: 1;
  color: var(--color-white);
}

.editInp::-moz-placeholder {
  opacity: 1;
  color: var(--color-white);
}

.editInp:-ms-input-placeholder {
  opacity: 1;
  color: var(--color-white);
}

.smLogo h2 {
  font-size: 20px;
}

.successCircle {
  width: 280px;
  height: 280px;
  background: var(--color-green);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.successTxt {
  color: var(--color-green);
}

.successTxt h2 {
  font-size: 22px;
  font-weight: 700;
}

.successTxt p {
  font-size: 12px;
}

.delIcon {
  color: var(--color-red);
  font-size: 40px;
}

.theme-accordion .accordion-button {
  margin: 0;
  padding: 10px 0;
  border: none;
  border-radius: 0;
  background: none;
  font-size: 18px;
  color: #fff !important;
}

.theme-accordion .accordion-button::after {
  color: #fff !important;
}

.theme-accordion .accordion-button:focus,
.theme-accordion .accordion-button:focus-visible {
  outline: 0 !important;
  box-shadow: none !important;
}

.theme-accordion .accordion-item {
  background: none;
  border: 1px solid rgba(0, 0, 0, .125);
}

.theme-accordion .accordion-body {
  padding: 0;
  padding-bottom: 10px;
}

.theme-accordion .accordion-body p {
  font-size: 13px;
  padding-top: 10px;
}

.theme-accordion .accordion-button::after {
  opacity: 0 !important;
}

.theme-accordion .accordion-button span {
  position: relative;
  padding: 0 20px 0 0;
}

.theme-accordion .accordion-button span::after {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAQCAYAAADESFVDAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjMwMkIyOEVCRjEyRDExRUM4OTc0RkU3MUJDRUY2NTA0IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjMwMkIyOEVDRjEyRDExRUM4OTc0RkU3MUJDRUY2NTA0Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MzAyQjI4RTlGMTJEMTFFQzg5NzRGRTcxQkNFRjY1MDQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MzAyQjI4RUFGMTJEMTFFQzg5NzRGRTcxQkNFRjY1MDQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7xtoT9AAAAeklEQVR42mJQUFDgqa+vzwHR////Z8CGGe7fv38ayPgPonEpBBGv/0MBSKGDg4MOXkW4FGIowqYQqyJ0hTgVIStkBCliYGAQYcABHjx4wMDEQACAFBFlHfkOJxgEBAMTZ7Ts379/NUghLgVgRaCYT0hI8MGVTEAYIMAA0YCWeyfl7owAAAAASUVORK5CYII=) no-repeat !important;
  bottom: 2px;
  content: "";
  flex-shrink: 0;
  height: 16px;
  margin-left: auto;
  position: absolute;
  right: 0;
  transition: all .5s ease;
  width: 9px;
}

.accordion-button:not(.collapsed) span:after {
  transform: rotate(90deg);
}

.accordion-button:not(.collapsed) {
  outline: 0 !important;
  box-shadow: none !important;
}

.feedIcon {
  width: 90px;
  height: 90px;
  background: var(--color-white);
  border-radius: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 40px;
}

/* Custom Checkbox */
.custom-check {
  display: block;
  position: relative;
  padding-left: 35px;
  padding-top: 2px;
  cursor: pointer;
  color: var(--color-grey);
  font-size: 11px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 2.1;
}

.custom-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9;
}

.custom-check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #223f5d;
  border-radius: 5px;
}

.custom-check input:checked~.checkmark {
  background-color: #223f5d;
}

.custom-check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-check input:checked~.checkmark:after {
  display: block;
}

.custom-check .checkmark:after {
  left: 9px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: solid var(--color-white);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-check.circle .checkmark {
  border-radius: 50px;
  background: none;
  border: 2px solid var(--color-grey);
}

.custom-check.circle input:checked~.checkmark {
  border: 2px solid var(--color-light-blue);
}

.custom-check.circle input:checked~.checkmark:after {
  opacity: 0;
  visibility: hidden;
}

.custom-check.single {
  position: relative;
  width: 25px;
  height: 25px;
}

.categories-wrapper .custom-check.single {
  position: absolute;
  right: 15px;
  top: 22px;
  z-index: 9;
}

/* Custom Checkbox */

/* scrollFix */
.screenWithoutFooter .loginWrap {
  max-height: 100vh !important;
}

.pageHeaderFooter .loginWrap.scrollable,
.pageHeaderFooter .loginWrap {
  max-height: 100vh !important;
  height: 100vh !important;
  padding-bottom: 90px;
  cursor: pointer;
}

/* scrollFix */

.iconBlock {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background: var(--color-white);
  color: var(--color-light-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  position: absolute;
  right: 0;
}

.themeModal.centered .modal-dialog-centered {
  align-items: center !important;
}

.themeModal.centered .modal-content {
  border-radius: 10px !important;
}

/* Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-red);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #00ff80;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Switch */

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

.loadMoreBtn {
  background-color: blue;
  padding: 3px;
  margin-top: 6px;
  border-radius: 3px;
  cursor: pointer;
}

.cardElement {
  width: 100%;
  padding: 10px;
  padding-top: 21px;
  border-radius: 8px;
  /* background: var(--colo-blue) !important; */
  background-color: #141D28;
  border: none;
  font-size: 15px;
  font-weight: 700;
  color: white !important;
}

.subscription {
  border-radius: 13px;
  margin-top: 8px;
  position: relative;
  width: 100%;
  opacity: 0.5;
}

.selectedSubscription {
  border-radius: 13px;
  margin-top: 8px;
  position: relative;
  /* border: 1px solid green; */
  width: 100%;
}

.subscriptionContainer {
  position: relative;
  width: 48%;
}

.btnBottom {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 1;
  padding-bottom: 8px;
}

.perks {
  margin-top: 25px;
  height: auto;
  max-height: 17vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.perks::-webkit-scrollbar {
  display: none;
}

.avatar {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.privatterList {
  overflow-y: auto;
  height: 70%;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.privatterList::-webkit-scrollbar {
  display: none;
}

.subTitle {
  margin-bottom: 26px;
  margin-top: 5px;
}

/* deactivate modal */
.deactivate {
  width: 100%;
  margin-top: 7px;
  margin-bottom: 31px;
  border: none;
  background: var(--colo-blue);
  border-radius: 9px;
  padding: 12px;
  font-size: 14px;
  color: #fff;
}

.deactTitle {
  font-size: 23px;
  color: white;
}

.decBtnContainer {
  margin-top: 30px;
}

.flexx {
  display: flex;
  justify-content: space-between;
}

.signupHeader {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  min-height: 80px;
  display: flex;
  align-items: center;
}

.pageWrap {
  max-height: 100vh !important;
  height: 100vh !important;
  padding-top: 80px !important;
  padding-bottom: 90px !important;
  cursor: pointer;
  width: 100%;
  position: relative;
}

.unReadMsg {
  color: gray;
}

/* .wInpWrap2 {
  width: 173px;
} */
.modal-dialog {
  max-width: 380px;
}

.errorDiv {
  max-height: 100vh !important;
  height: 87vh;
  padding-top: 80px;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

/* cursor pointer */

.cursor-pointer {
  cursor: pointer;
}

.attachment-img {
  position: relative;
  width: fit-content;
}

.attachment-img img {
  width: 120px;
  height: 120px;
  overflow: hidden;
}

.attachmentClose {
  position: absolute;
  right: 5px;
  font-weight: 800;
  cursor: pointer;
}

.attachmentClose img {
  height: 16px;
  width: 16px;
}

.text-capitalize {
  text-transform: capitalize;
}

.custom-control-input {
  height: 15px;
  width: 15px;
  transform: scale(1.2);
}


.categories-wrapper {
  position: relative;
}

.categories-wrapper img {
  z-index: 9;
}

.category-input {
  position: absolute;
  top: 10px;
  right: 6px;
  opacity: 0;
  width: 10%;
  height: 10%;
  z-index: 9;
}

.subscriptionPopup {
  height: auto;
  /* 60px */
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  overflow: hidden;
  position: relative;
  font-weight: 700;
  font-style: normal;
  font-family: "Montserrat", serif;
  color: #ffffff;
  font-size: 25px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: center;
}

.subscriptionBtn {
  height: min-content;
  /* 50px */
  align-items: center;
  padding: 16px 24px 16px 24px;
  background-color: var(--secondary, #223f5d);
  overflow: visible;
  position: relative;
  align-content: center;
  flex-wrap: nowrap;
  gap: 10;
  width: 40%;
  border: none;
  border-radius: 8px;
}

.selectMe {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.privatterList input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.privatterList input[type='radio']:checked {
  background: var(--color-green);
}

.sponsorName {
  font-size: 15px;
  font-weight: 600;
  padding-left: 10px;
}

.overflowText {
  white-space: nowrap;
  width: 40%;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: right;
}

.countDown {
  position: absolute;
  top: 20px;
  width: 100%;
  background-color: var(--color-light-blue);
  text-align: center;
  counter-reset: #fff;
  margin-top: 60px;
  padding: 10px;
  z-index: 99;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.eventName {
  flex-shrink: 0;
  width: auto;
  /* 174px */
  height: auto;
  /* 14px */
  white-space: pre;
  position: relative;
  font-weight: 400;
  font-style: normal;
  font-family: "Montserrat", serif;
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 1.2;
}

.eventDay {
  flex-shrink: 0;
  width: auto;
  /* 174px */
  height: auto;
  /* 14px */
  white-space: pre;
  position: relative;
  font-weight: 400;
  font-style: normal;
  font-family: "Montserrat", serif;
  color: #ffffff;
  font-size: 8px;
  letter-spacing: 0px;
  font-weight: 600;
}

.eventCounterTime {
  flex-shrink: 0;
  width: auto;
  /* 48px */
  height: auto;
  /* 43px */
  white-space: pre;
  position: relative;
  font-weight: 700;
  font-family: "Inter-Bold", "Inter", sans-serif;
  color: #ffffff;
  font-size: 36px;
  letter-spacing: 0px;
  line-height: .8;
  vertical-align: middle;

}

.tc {
  text-decoration: none !important;
  /* font-style: italic; */
  font-size: 11px;
  /* line-height: 1; */
  color: #8B8E92;
}

.tcModal {
  background-color: #223f5d;
  color: white;
  border-radius: 6px;
  padding-top: 130px;
  padding-bottom: 130px;
  position: relative;
}

.scrollable-leaderBoard {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 100px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.scrollable-leaderBoard::-webkit-scrollbar {
  display: none;
}

.leaderCard {
  margin: 20px 0;
  background-color: var(--colo-blue);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 30px 30px 30px;
  overflow: visible;
  flex: 1 0 0px;
  position: relative;
  align-content: center;
  flex-wrap: nowrap;
  gap: 16;
  border-radius: 9px;
}

.leaderCard img {
  border-radius: 100%;
  height: 111px;
  width: 111px;
}

.leaderCard .first-place {
  margin-top: 16px;
  flex-shrink: 0;
  width: auto;
  /* 200px */
  height: auto;
  /* 43px */
  white-space: pre;
  position: relative;
  font-weight: 700;
  font-style: italic;
  font-family: "Montserrat", serif;
  color: #ffffff;
  font-size: 36px;
  letter-spacing: 0px;
  line-height: 1.2;
}

.leaderCard p {
  flex-shrink: 0;
  width: auto;
  /* 181px */
  height: auto;
  /* 19px */
  white-space: pre;
  position: relative;
  font-weight: 500;
  font-style: normal;
  font-family: "Montserrat", serif;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 1.2;
}

.leaderCard .links {
  display: flex;
}

.leaderCard .columns {
  margin: 16px 10px;
  box-sizing: border-box;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background-color: var(--accent, #3388ff);
  color: #fff;
  overflow: visible;
  position: relative;
  align-content: center;
  flex-wrap: nowrap;
  gap: 10;
  aspect-ratio: 1 / 1;
  border-radius: 20px;
}

.garantee-row {
  display: flex;
  padding: 38px 0 18px 0;
  justify-content: space-between;
}

.icon-row {
  display: flex;
  justify-content: space-between;

  padding-top: 18px;
}

.info-I {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.info-I img {
  color: #fff;
  background: #fff;
  border-radius: 50%;
  padding: 0;
  margin: 0;
}

.loadingAdScreen {
  position: absolute;
  bottom: 70px;
  text-align: center;
  width: 95%;
  height: 160px;
  z-index: 999;
}

.loadingAdScreen img {
  /* width: 100%; */
  margin-top: 4px;
}

.loadindAdscreen2 {
  width: 90%;
  background: #fff;
  height: 60px;
  margin-top: 4px;
}

.createwagerAdDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 76vh;
}

.wager-ads1 {
  max-height: 130px;
  width: 206px;
  height: 130px;
  background-color: transparent;
  border-radius: 4px;
}

.wager-ads2 {
  max-height: 130px;
  width: 206px;
  height: 130px;
  background-color: transparent;
  border-radius: 4px;

}

.adDasboard {
  height: 180px;
  background-color: transparent;
  overflow: hidden;
  padding: 0;
}

/* profile tabs */
.profile_tab {
  background-color: #000812;
}

.profile_tab .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #fff;
  background-color: transparent !important;
  border-color: none !important;
  border-bottom: 2px solid #3388ff;
  /* border-right: 1px solid #223F5D; */

}

.profile_tab .nav-tabs .nav-link {
  color: #606061;
  margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  font-weight: 500;
}

.wraggerDetails .chipList-single .chipList-left {
  font-size: 14px;
  width: 55%;
}

.wraggerDetails .chipList-single .chipList-right {
  font-size: 14px;
  width: 45%;
  text-align: end;
}

.cancel_wager {
  padding: 5px 30px;
  border: none;
  background: #FD2C44;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
}

.accept_wager {
  background: #3388FF;
  border: none;
  padding: 6px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
}

.cancel_post {
  padding: 6px 12px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #8B8E92;
  border: 2px solid #8B8E92;
  border-radius: 4px;
  background-color: transparent;
}

.delete_post {
  background: #FD2C44;
  border-radius: 4px;
  padding: 7px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #FFFFFF;
  border: none;
}

.delete_wager_text {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  color: #8B8E92;
  display: inline-block;
}

.footer_create_wager {
  position: absolute;
  bottom: 100%;
  left: 27%;
  border: 1px solid #fff;
  background: #06101D;
  padding: 12px;
  text-align: center;
  border-radius: 5px;
}

.footer_create_wager h3 {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}

.footer_create_wager ul li {
  background: #fff;
  color: #06101D;
  border-radius: 5px;
  padding: 8px;
  font-weight: 500;
  margin: 8px 0;
  cursor: pointer;
}

.footer_create_wager ul li a {
  text-decoration: none;
  color: #06101D;
}

.footer_create_wager span {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #FFFFFF;
}

.create_post_div {
  background: #06101D;
  margin-top: 30px;
}

.create_post_div .userIconDiv {
  height: 42px;
  width: 42px;
}

.create_post_div .userIconDiv img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.create_post_div .user_name {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
}

.create_post_div .create_div textarea {
  resize: none;
  /* height: 50vh; */
  width: 100%;
  background: transparent;
  border: none;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  vertical-align: bottom;
}

.create_post_div .create_div textarea::placeholder {
  font-size: 12px;
  font-weight: 600;
  color: #6B6B6B;


}

.create_post_div .create_div span {
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #6B6B6B;
}

.login_new_css {
  min-height: 90vh !important;
}






/*  */

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.profile_tab .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #fff;
  background-color: transparent !important;
  border-color: none !important;
  border-bottom: 2px solid #3388ff;
}

.profile_tab .nav-tabs .nav-link {
  color: #606061;
  margin-bottom: calc(var(--bs-nav-tabs-border-width) * -1);
  background: none;
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  font-weight: 500;
}

.postsDiv {
  border-bottom: 3px solid #141D28;
}

.postsDiv .userDetails {
  width: 100%;
  position: relative;
  padding: 13px;

}

.postsDiv .userDetails .userIconDiv {
  height: 41px;
  width: 41px;

}

.postsDiv .userDetails .userIconDiv img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 1px solid #fff;
}

.postsDiv .userDetails .user_name {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  color: #FFFFFF;
  vertical-align: bottom;
}

.postsDiv .userDetails .time {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: rgba(139, 142, 146, 0.5);
}

.postsDiv .userDetails .wager_name {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #fff;
}

.create_post_div .userDetails .wager_name {
  display: block;
  margin-top: 5px;
  line-height: 0;
}

.postsDiv .userDetails .trash {
  position: absolute;
  right: 13px;
  top: 33%;
}

.postsDiv .userDetails .spots {
  position: absolute;
  right: 13px;
}

.postsDiv .userDetails .trash img {
  height: 14px;
}

.postsDiv .postDesc {
  /* margin: 16px 0; */
  padding: 6px 13px;
  height: auto;

}

.postsDiv .postDesc p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  white-space: break-spaces;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.postsDiv .likeDiv {
  padding: 10px;
  align-items: center;

}

.postsDiv .likeDiv img {
  /* height: 12px; */
  width: 16px;
  margin-right: 8px !important;

}

.postsDiv .likeDiv span {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 15px;
  color: #8B8E92;
}

.postsDiv .likeDiv button {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: #FFFFFF;
  padding: 5px 10px;
  background: #3388FF;
  border-radius: 4px;
  border: none;
}

.postsDiv .wager_details_div {
  background-color: #0F1823;
  padding: 30px 12px;
}

.postsDiv .comments_div {
  padding: 0 0 10px 12px;
}

.postsDiv .comments_div span:nth-child(1) {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
}

.postsDiv .comments_div span:nth-child(2) {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
}

.postsDiv .comments_div a {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #8B8E92;
}

.wager_details_div h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;

}

.wager_details_div p {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #FFFFFF;

}

.wager_details_div i {
  font-style: italic;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
}

.wager_details_div .wager_details_ul li {
  margin: 10px 0;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: inherit;
  padding: 5px 6px;

}

.wager_details_ul2 {
  font-size: 12px;
}

.wager_details_div .wager_details_ul2 li {
  margin: 16px 0;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: inherit;
  padding: 5px 6px;
}

.wager_details_div .wager_details_ul li span {
  border: 2px solid #fff;
  padding: 4px 6px;
  color: #fff;
  white-space: nowrap;
}

.wager_details_div .wager_details_ul2 li span {
  border: 2px solid grey;
  padding: 4px 6px;
  color: grey;
  white-space: nowrap;
}

.wager_details_div .wager_details_ul2 li:last-child span {
  border: 2px solid #fff;
  padding: 4px 6px;
  color: #fff;
  white-space: nowrap;
}

.wager_details_div .wager_details_ul li span span {
  border: none;
}

.wager_details_div .wager_details_ul2 li span span {
  border: none;
}

/* .wager_details_div .wager_details_ul2 li span span:last-child {
  border: none;
} */
.my_wager_div {
  background-color: #06101D;
  border-bottom: 1px solid #223F5D;
  padding: 16px 12px;
}

.my_wager_div h3 {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
}

.my_wager_div i {
  font-weight: 400;
}

.my_wager_div li {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #8B8E92;
}

.my_wager_div .right_carot_div {
  position: absolute;
  right: 10px;
  top: 40%;

}

.my_wager_div .right_carot_div svg {
  height: 21px;
}

.text {
  font-size: 12px;
  padding: 0;
}




/*  */

/* add */
.ad_div {
  background: #06101D;
}

.ad_sponsored_div {
  padding: 15px 12px;
}

.ad_div span {
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  color: #FFFFFF;
}

.ad_div button {
  background: #3388FF;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
  padding: 6px;
  border: none;
  margin: 15px 12px;
}

.ad_img_div {
  /* height: 200px; */
  width: 100%;
  aspect-ratio: 16 / 9;
}

.ad_img_div img {
  height: 100%;
  width: 100%;
  aspect-ratio: 16 / 9;

}


/*  */
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Modification */
.pageTop-right.alt.threeIcon {
  width: 160px;
}

.btn-dropBtn {
  border: none !important;
}

.btn-dropBtn:focus {
  outline: 0 !important;
  border: none !important;
}

.welcomeForm-single label span.underTxt {
  border-bottom: 1px solid !important;
  font-style: italic;
}

select.simpleInp {
  padding-right: 27px;
  height: 46px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.subscriptionContainer img {
  border: 1px solid #06101d;
}

.subscriptionContainer img.selectedSubscription {
  border: 1px solid green;
}

.subscriptionContainer {
  width: 48%;
}

.privatterList {
  height: calc(100vh - 290px);
}

.privatterList {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.privatterList::-webkit-scrollbar {
  display: none;
}

.backLink-icon.alt.noBg,
.backLink-icon.noBg {
  transform: translateX(-5px);
}

.eventInfo {
  text-transform: uppercase;
}

.userName {
  font-size: 18px;
}

.welcomeForm-single .custom-check {
  margin-bottom: 15px;
}

.wraggerDetailsBox {
  padding-top: 20px;
}

.scrollable-chat {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollable-chat::-webkit-scrollbar {
  display: none;
}

.confList ul li {
  padding: 15px 0 !important;
}

.fullTxt.link.pt-3 {
  padding-top: 5px !important;
}

.notific-single-txt p span.time {
  right: 10px !important;
}

.signupHeader.zindex-99 {
  padding: 0 15px;
}

.otpInp {
  height: 53px;
  max-height: 53px;
}

.backLink span {
  font-size: 20px;
}

.filterDrop .dropdown-menu {
  transform: none !important;
  margin: 30px 0 0 0 !important;
}

.remove_filter:hover {
  background: transparent;
}

.dropdown-group {
  margin-top: 7px;
  cursor: pointer;
}

.dropdown-group:first-child {
  margin-top: 0;
}

.filterDrop-alt .singleMenu {
  margin: 0;
}

.backLink span.chatName span {
  font-size: 14px !important;
}

.backLink span.chatName>span {
  display: block;
}

.notific-single-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.userImg {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pageBottom.chatInp {
  justify-content: space-between;
}

.perks {
  max-height: 22vh;
}

.notific-single-txt p.fullTxt.link a:last-child {
  display: inline-block;
  margin-left: 0;
}

html {
  overflow: hidden;
}

.fullScreen {
  overflow: auto;
}

.editInp {
  border-radius: 0;
}

.btn-full {
  width: 100%;
  min-width: 100%;
}

.bgBlue {
  background: #06101d;
}

.removeScrollbar::-webkit-scrollbar {
  display: none;
}

.removeScrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Formula 1 changes */
.dropdown_sport {
  color: #fff;
  font-weight: 800;
  font-size: 26px;
  line-height: 26px;
  padding-left: 0;
  padding-bottom: 0;
  outline: none;
  border: none;
  font-family: sans-serif;
}

.dropdown_sport:hover {
  outline: none;
  border: none;
}

.dropdown_sport_menu {
  background-color: #06101d;
  border: 2px solid #fff;
  padding: 5px;
}

.dropdown_sport_menu span {
  color: #fff;
  /* margin: 5px; */
  margin: 0px;
  font-weight: 600;
}

.dropdown_sport_menu a {
  color: #000;
  background-color: #fff;
  font-weight: 600;
  margin: 6px;
  width: auto;
  padding: 4px 10px;
  justify-content: space-between;
}

/* Login new UI */
.span_moto {
  color: #2196f3;
  font-size: 26px;
  font-weight: 700;
}

.span_bookie {
  font-size: 26px;
  font-weight: 700;
}


/*  */


/* Counter wager css */
.counterModal {}

.counterModal .modal-dialog {
  margin-top: 0;
  height: 100%;
  margin: 0 auto;
  margin-bottom: 0;
  width: inherit;
  max-width: 400px;
}

.counterModal .modal-content {
  position: absolute;
  /* top: 10px; */
  bottom: 0%;
  background-color: transparent;

}

.counterModal .modal-body {
  background-color: rgb(6, 16, 29);
  color: #fff;
  /* padding-left: 0; */
  /* margin: 0 5px; */
  padding: 5px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  font-size: 12px;
}

.counterModal .counter_heading {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-top: 16px;
}

.counterModal .chipHeading {
  margin: 10px 0;
  padding: 0px 0px 0 12px;
  border-left: 5px solid #3388FF;
  background-color: rgb(6, 16, 29);
}

.counterModal .chipHeading h2 {
  font-size: 16px;
}

.counterModal .wager_details_div {
  background-color: #0F1823;
  padding-left: 12px;
}

.counterModal .chip_counter {
  padding: 12px 0 10px 10px;
  margin-top: 10px;
}

.counterModal .chip_counter label {
  font-size: 13px;
}

.counterModal .chip_counter input {
  border: none;
  background-color: #223f5d;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 6px 10px;
  color: #fff;
  font-size: 12px;
}

.counterModal .chip_counter input::placeholder {
  color: #fff;
  opacity: 1;
  /* Firefox */
  font-weight: 500;
}

.counterModal .chip_counter input::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #fff;
  font-weight: 500;

}

.counterModal .counter_top_border {
  border: 2px solid #fff;
  border-radius: 8px;
  width: 25%;
  text-align: center;
  margin: 0 auto;
}

.counterModal .counter_set {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin: 5px 0;
}

.counterModal .counter_info {
  font-size: 12px;
  text-align: center;
}

.counterModal .counter_button {
  text-align: center;
  margin: 10px 0;
}





/*  */


/* CSS specific to iOS devices */
@supports (-webkit-touch-callout: none) {
  body {
    min-height: auto;
    min-height: -webkit-fill-available;
  }

  html {
    min-height: auto;
    min-height: -webkit-fill-available;
  }

  .fullScreen {
    min-height: auto;
    min-height: -webkit-fill-available;
  }

  .pageHeaderFooter .loginWrap.scrollable,
  .pageHeaderFooter .loginWrap {
    padding-bottom: 150px !important;
  }

  .loginWrap.scrollable {
    padding-bottom: 90px !important;
    height: auto !important;
  }
}

.hideScrollBar::-webkit-scrollbar {
  width: 0px;

}

.hideScrollBar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* CSS for other than iOS devices */
@supports not (-webkit-touch-callout: none) {}



/* .dropdown-group .singleMenu:first-child{ margin-top: 0;} */
/* Modification */

@media (max-height: 500px) {

  /* .pageBottom{ position: static !important;}
  .pageTop{ position: static !important;}
  .pageTopFixed{ position: fixed !important;}
  .pageBottomFixed{ position: fixed !important;}
  .fullScreen-login{ height: auto; min-height: 100vh;}
  .pageHeaderFooter .loginWrap.scrollable, .pageHeaderFooter .loginWrap{ padding-top: 0 !important; padding-bottom: 0 !important;}
  .fullScreen{ padding-left: 0; padding-right: 0;}
  .pageTop + div{ padding-left: 20px; padding-right: 20px;}
  .wraggerDetails .welcomeForm{ padding: 0;} */
  /* .loginWrap{ margin-top: 50px; margin-bottom: 50px;} */
  .pageHeaderFooter .loginWrap.scrollable,
  .pageHeaderFooter .loginWrap {
    padding-bottom: 120px !important;
  }

  .keyOpen {
    position: static !important;
  }
}



/* CSS Modification for Desktop Only */
@media (min-width: 768px) {
  body {
    background: white;
  }

  .login-solid.no-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .pageTop {
    position: absolute;
  }

  .pageBottom {
    position: fixed;
    width: 400px;
    /* margin: 0 0 0 -20px; */
    left: 50%;
    margin-left: -200px;
    background: #06101d;

  }
}

@media (max-width: 768px) {
  .appWrap {
    overflow: hidden;
  }

  .pageBottom {
    position: fixed !important;
    width: 100%;
    left: 0;
    bottom: 0 !important;
    z-index: 999 !important;
    -webkit-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    /* height: 290px; */
    background: #06101d;

  }

  .pageTop {
    padding-left: 20px !important;
    padding-right: 20px !important;
    position: fixed;
    z-index: 9 !important;
  }

  .mobileWrap {
    margin-bottom: 16%;
  }

  .mobileWrap .pageBottom {
    padding-bottom: 10px;
  }

  .pageHeaderFooter .loginWrap.scrollable,
  .pageHeaderFooter .loginWrap {
    padding-bottom: 150px;
  }
}

@media (max-width: 767px) {
  .welcomeForm-single label {
    font-size: 14px;
  }
}

@media (max-width: 400px) {}

/* CSS Modification for Desktop Only */

/* CSS Modification for Landscape View only */
@media screen and (orientation: landscape) and (max-device-width: 768px) {
  /* .pageBottom {
    position: fixed;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .pageTop {
    position: static;
    padding-left: 0;
    padding-right: 0;
  } */
}

/* CSS Modification for Landscape View only */

/* CSS Modification for lower Resolution on Mobile */
@media(max-width: 414px) and (min-width: 376px) {
  .commentSrcrollDiv {
    height: 50vh !important;
    overflow: auto;
    width: 95%;
  }
}

@media (max-width: 376px) {
  body {
    font-size: 12px !important;
  }

  .logo img {
    width: 200px;
  }

  .infoTxt h1 {
    font-size: 16px;
  }

  .pageTop {
    padding: 10px;
  }

  .notific-single-txt p.fullTxt.link a:last-child {
    display: inline-block !important;
    margin-left: 0 !important;
  }

  .pageHeaderFooter .loginWrap.scrollable,
  .pageHeaderFooter .loginWrap {
    padding-bottom: 120px !important;
  }

  .span_moto {
    font-size: 22px;
  }

  .span_bookie {
    font-size: 22px;
  }

  /* .logo {
    width: 51%;
  } */

}

/* CSS Modification for lower Resolution on Mobile */


.paddingtop {
  padding-top: 0px !important;
}

.loginWrap.scrollable-notification {
  overflow-y: auto;
  overflow-x: hidden;
}

@media (max-width: 320px) {

  .logo_div {
    width: 52%;
  }

  .span_moto {
    font-size: 21px;
  }

  .span_bookie {
    font-size: 21px;
  }
}


/* DONT DELETE THIS BELONGS TO ACTUAL APP LOLOL*/

#wager-caption {
  background-color: transparent;
  border: none;
  color: white;
}

#toggle-switch-wrapper {
  display: flex;
  width: 100%;
  height: 62px;
  border-radius: 200px;
  overflow: hidden;
  position: relative;
  background-color: transparent;
  padding: 0;
}

#toggle-switch-button {
  position: absolute;
  z-index: 1;
  border: 0;
  width: 50%;
  height: 62px;
  border-radius: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: .3s ease-in-out;
}

.toggle-switch-item {
  color: white;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 50%;
  height: 100%;
}

.toggle-left {
  transition: .3s ease-in-out;
  transform: translateX(0);
}

.toggle-right {
  transition: .3s ease-in-out;
  transform: translateX(100%);
}